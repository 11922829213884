/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import { Link } from "gatsby";

import { containerStyles, listStyles, dateStyles } from "./List.styles";
import { propTypes, defaultProps } from "./List.propTypes";

// I pass all the markdown data from the mdxrenderer
const List = (props) => {
	var { edges } = props.data;

	var { listTitle, CTA } = props;
	return (
		<div sx={containerStyles}>
			<Text as="h2" variant="bodyTitle" sx={{ marginBottom: 4 }}>
				{listTitle}
			</Text>
			{edges.map(({ node }) => {
				var { frontmatter, fields, fileAbsolutePath, id } = node;
				if (fileAbsolutePath.toLowerCase().includes(props.listType)) {
					return (
						<ul sx={listStyles} key={id}>
							<li sx={dateStyles}>{frontmatter.date}</li>
							<List.titleLink
								frontmatter={frontmatter}
								fields={fields}
								id={id}
							/>
						</ul>
					);
				}
			})}
			<Link
				to={CTA.slug}
				sx={(props) => {
					return { ...props.links.primary };
				}}
			>
				{CTA.title}
			</Link>
		</div>
	);
};

export default List;

List.defaultProps = defaultProps;

List.propTypes = propTypes;

// use Gatsby Link if it's internal and an a href if it's external
List.titleLink = ({ frontmatter, fields, id }) => {
	if (frontmatter.externalSlug) {
		return (
			<li>
				<a
					href={frontmatter.externalSlug}
					target="_blank"
					rel="noreferrer"
					sx={(props) => {
						return {
							...props.links.primary,
						};
					}}
					key={id}
				>
					{frontmatter.title}
				</a>
			</li>
		);
	} else if (fields.slug) {
		return (
			<li>
				<Link
					to={`${fields.slug}`}
					sx={(props) => {
						return {
							...props.links.primary,
						};
					}}
				>
					{frontmatter.title}
				</Link>
			</li>
		);
	}
	return (
		<Text variant="body" sx={{ color: "text" }} as="li">
			{frontmatter.title}
		</Text>
	);
};
