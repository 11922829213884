/** @jsx jsx */
import { jsx, Text, useColorMode } from "theme-ui";
import React from "react";

import { MdWbSunny } from "react-icons/md";
import { IoMdMoon } from "react-icons/io";

import { desktopContainerStyles, desktopTextStyles } from "./Index.styles.js";

export const DesktopThemeToggle = () => {
	var [colorMode, setColorMode] = useColorMode();
	function changeColor() {
		setColorMode((curMode) => {
			return updateColorMode(curMode);
		});
	}
	return (
		<li>
			<button
				sx={desktopContainerStyles}
				onClick={changeColor}
				aria-label={
					colorMode === "default" || colorMode === "light"
						? "switch to dark mode"
						: "switch to light mode"
				}
			>
				<Text as="p" variant="nav" sx={desktopTextStyles}>
					{displayColorMode(colorMode)}
				</Text>
				{displayColorIconDesktop(colorMode)}
			</button>
		</li>
	);
};

export const MobileThemeToggle = () => {
	var [colorMode, setColorMode] = useColorMode();
	function changeColor() {
		setColorMode((curMode) => {
			return updateColorMode(curMode);
		});
	}
	return (
		<button
			sx={{ paddingTop: "4px", marginRight: 4, border: 0, background: 0 }}
			onClick={changeColor}
			aria-label={
				colorMode === "default" || colorMode === "light"
					? "switch to dark mode"
					: "switch to light mode"
			}
		>
			{displayColorIconMobile(colorMode)}
		</button>
	);
};

//  color mode functions:
//  update the color
function updateColorMode(curMode) {
	return curMode == "light" || curMode == "default" ? "dark" : "light";
}
// display the color mode
function displayColorMode(colorMode) {
	return colorMode == "light" || colorMode == "default"
		? "light mode"
		: "dark mode";
}
// display the correct desktop icon
function displayColorIconDesktop(colorMode) {
	return colorMode == "light" || colorMode == "default" ? (
		<MdWbSunny size={28} sx={{ color: "text" }} />
	) : (
		<IoMdMoon size={24} sx={{ color: "text" }} />
	);
}
// display the correct mobile icon
function displayColorIconMobile(colorMode) {
	return colorMode == "light" || colorMode == "default" ? (
		<MdWbSunny size={28} sx={{ color: "primary" }} />
	) : (
		<IoMdMoon size={24} sx={{ color: "primary" }} />
	);
}
