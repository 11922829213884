/** @jsx jsx */
import { jsx, Text, useColorMode } from "theme-ui";
import React from "react";
import { Link } from "gatsby";

import { DesktopThemeToggle } from "./themetoggle/Index.js";

import MobileNav from "./mobile/Index";
import { routes } from "../../utils/routes";

/* <AniLink paintDrip to="page-3" duration={1}>
  Go to Page 3
</AniLink> */

import {
	containerStyles,
	nameStyles,
	mainNavStyles,
	linkStyles,
} from "./index.styles";

const Navigation = () => {
	return (
		<header>
			<nav sx={containerStyles} role="navigation">
				<Link
					sx={(props) => {
						return { ...props.links.nav, ...nameStyles };
					}}
					to="/"
					aria-label="back to home page"
				>
					<Text
						variant="nav"
						sx={{ lineHeight: 0 }}
						aria-label="back to home page"
					>
						Jeremy Barnes
					</Text>
				</Link>
				<MobileNav />
				<div sx={mainNavStyles}>
					<Text variant="nav" as="ul">
						{routes.map((route, i) => {
							if (!route.name.includes("Design")) {
								return (
									<Navigation.internalLink
										route={route}
										key={i}
									/>
								);
							}
							return (
								<Navigation.externalLink
									route={route}
									key={i}
								/>
							);
						})}
						<DesktopThemeToggle />
					</Text>
				</div>
			</nav>
		</header>
	);
};

export default Navigation;

Navigation.internalLink = ({ route }) => {
	return (
		<li>
			<Link
				sx={(props) => {
					return {
						...props.links.nav,
						...linkStyles,
					};
				}}
				activeClassName="active"
				partiallyActive={true}
				to={`${route.path}`}
			>
				{route.name}
			</Link>
		</li>
	);
};

Navigation.externalLink = ({ route }) => {
	return (
		<li>
			<a
				sx={(props) => {
					return {
						...props.links.nav,
						...linkStyles,
					};
				}}
				href={`${route.path}`}
				target="_blank"
				rel="noreferrer"
			>
				{route.name}
			</a>
		</li>
	);
};

// var [mobile, toggleMobile] = useState(false);
// useEffect(() => {
// 	var mq = window.matchMedia(`(max-width: 768px)`);
// 	console.log("mobile", mobile, "mq", mq);
// 	// updateNav(mq);
// 	toggleMobile(mq.matches);
// 	mq.addListener(() => toggleMobile(mq.matches));
// }, []);
// {mobile ? <MobileNav /> : null}
